<template>
  <div class="exercises">
    <div v-loading="loading" class="box" element-loading-background="rgba(0, 0, 0, 0)">
      <div class="search">
        <el-select
          v-model="questionType"
          clearable
          placeholder="选择题型"
          @change="onLoad"
          @clear="onLoad"
        >
          <el-option
            v-for="item in ea_question_type"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select
          v-model="type"
          clearable
          placeholder="选择类型"
          @change="onLoad"
          @clear="onLoad"
        >
          <el-option
            v-for="item in ea_question_category"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select
          v-model="level"
          clearable
          placeholder="选择难度"
          @change="onLoad"
          @clear="onLoad"
        >
          <el-option
            v-for="item in ea_question_difficulty"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div :class="total!=0?'list-box':'middle-box'">
        <div v-for="(i,index) in materialList" :key="i.id" class="item">
          <div class="btn" style="display: flex">
            <span>题型：{{ queType[i.questionType] }}</span>
            <span>类型：{{ queCategory[i.type] }}</span>
            <span>难度：{{ queDifficulty[i.level] }}</span>
          </div>
          <div class="name">
            <span style="display:inline-block;font-size: 14px ; float:left;width: 80%; white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"> {{ i.questionName }}</span>
            <el-button class="box-el-button" size="mini" style="float: right" round type="primary" @click="getDetail(index)">查看</el-button>
          </div>

        </div>
        <el-empty v-if="total==0" description="暂无数据" :image="require('@/assets/images/empty.png')" />
      </div>
      <div v-if="total>0" class="pageBox">
        <div style="text-align: right;" class="page">
          <el-pagination
            background
            :current-page="currentPage"
            :page-size="pageSize"
            layout=" prev, pager, next, jumper"
            :total="total"
            @current-change="currentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ExeList } from '../../api/study'

export default {
  name: 'Exercises',
  computed: {
    ...mapState({
      ea_question_type: state => state.dict.dictTree.ea_question_type['arr'],
      ea_question_category: state => state.dict.dictTree.ea_question_category['arr'],
      ea_question_difficulty: state => state.dict.dictTree.ea_question_difficulty['arr'],
      queType: state => state.dict.dictTree.ea_question_type['obj'],
      queCategory: state => state.dict.dictTree.ea_question_category['obj'],
      queDifficulty: state => state.dict.dictTree.ea_question_difficulty['obj'],
      current: state => state.currentNode.id
    })
  },
  watch: {
    current() {
      this.onLoad()
    }
  },
  created() {
    this.onLoad()
  },
  data() {
    return {
      loading: true,
      questionType: '',
      type: '',
      level: '',
      options: [],
      materialList: [],
      currentPage: 1,
      pageSize: 6,
      total: 0
    }
  },
  methods: {
    onLoad(data) {
      const obj = {
        studentId: this.$store.state.user.userInfo.id,
        questionType: this.questionType,
        type: this.type,
        level: this.level,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        classId: this.$store.state.user.userInfo.classId,
        classOutlineId: this.current,
        ...data
      }
      ExeList(obj).then(res => {
        console.log(obj)
        console.log(res)
        if (res.code === 200) {
          this.loading = false
          this.materialList = res.rows
          this.total = res.total
        }
      })
    },
    currentChange(val) {
      this.currentPage = val
      this.onLoad()
    },
    getDetail(data) {
      this.$router.push({ params: { list: this.materialList, index: data }, name: 'studyExercisesDetail' })
    }
  }
}
</script>

<style scoped lang="less">
    .exercises{
        .box{
            width: 770px;
            min-height: 550px;
            /*background: #ffffff;*/
            margin: 10px;
            >.search{
                text-align: left;
                padding-top: 20px;
                padding-left: 20px;
                .el-select{
                    margin-right: 10px;
                    background: #ffffff;
                    ::v-deep.el-input__inner{
                        background: #ffffff;
                        border: 1px solid #BFC4CD;
                    }
                    ::v-deep.el-input__inner::placeholder {
                        color: #999999;
                    }
                }
            }
            >.list-box{
                padding-top: 13px;
                min-height: 550px;
                >.item{
                    text-align: left;
                    width: 730px;
                    height: 86px;
                    background: white;
                    margin:4px 20px;
                    border-radius: 10px;
                    >.name{
                        padding-left: 20px;
                        padding-right: 20px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 42px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                    }
                    .btn{
                        margin-top: 10px;
                        text-align: left;
                        padding-left: 20px;
                        margin-right: 20px;
                        span{
                          display: inline-block;
                          padding: 10px 64px 10px 0;
                          font-size: 14px;
                          font-family: Microsoft YaHei;
                          font-weight: 400;
                          /*color: #999999;*/
                          //padding-right: 64px;
                        }
                    }
                }
                .box-el-button:hover{
                    cursor: pointer;
                }
            }
            >.middle-box {
              padding-top: 144px;
            }
        }
        .pageBox{
            width: 770px;
            height: 40px;
            margin-top: 20px;
            position: relative;
            .page{
                position: absolute;
                right: 20px;
                bottom: 10px;
                //border: 1px solid #E8E8E8;
                width: auto;
            }
            //::v-deep.el-pagination{
            //    .el-pager{
            //        .number{
            //            color: #999999;
            //        }
            //        .active{
            //            color: #39A5EF;
            //        }
            //        .more{
            //            color: #999999;
            //        }
            //    }
            //}
        }
    }

</style>
